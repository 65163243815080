<template>
  <b-col cols="12">
    <b-card class="media-list">
      <b-row class="d-flex align-items-center">
        <b-col class="profile-side">
          <b-row class="my-0 css-reset">
            <b-media class="mb-2 css-reset">
              <b-img ref="previewEl" :src="userImg" class="profile-img my-2" center/>
            </b-media>
          </b-row>
          <b-row class="center profile-edit-buttons">
            <div class="d-flex flex-wrap">
              <b-button
                  variant="primary upload-button"
                  @click="toggleShow">

                <span class="d-none d-sm-inline">
                    <b-icon-capslock-fill class="up-icon"/>
                    <span class="up-text">{{ $t('Upload') }}</span>
                </span>
                <b-icon-capslock-fill class="up-icon d-inline d-sm-none"/>
              </b-button>
              <my-upload field="img"
                         @crop-success="cropSuccess"
                         v-model="show"
                         :width="150"
                         :height="150"
                         langType="tr"
                         :params="params"
                         :headers="headers"
                         img-format="png"
              >
              </my-upload>
              <b-button
                  variant="danger remove-button"
                  class="ml-1"
                  @click="removePhoto"
              >
                <span class="d-none d-sm-inline">
                  <b-icon-trash class="remove-icon"/>
                  <span class="remove-text">{{ $t('Remove') }}</span>
                </span>
                <b-icon-trash class="remove-icon d-inline d-sm-none"/>
              </b-button>
            </div>
          </b-row>
        </b-col>
        <b-col>
          <PersonEdit  :personData="personData" :isFinal="isFinal" :$v="$v" :male-color="maleColor" :female-color="femaleColor" :config="config" :getGender="getGender"/>

        </b-col>
      </b-row>
      <b-row class="d-flex justify-content-center mt-1" v-if="$v.$invalid">
        <span class="text-danger">{{
            $t('Please make sure to verify all fields')
          }}</span>
      </b-row>
      <b-row class="float-right mt-1">
        <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            class="mr-1 shadow cancel-button"
            size="md"
            variant="outline-danger"
            :to="{ name: 'studio_user_list', params: { studioID: this.$route.params.studioID} }"
        >
          <b-icon-dash-circle></b-icon-dash-circle>
          {{ $t('Cancel') }}
        </b-button>
        <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            size="md"
            variant="outline-success"
            :disabled="$v.$invalid"
            class="shadow update-button"
            @click="updatePersonData()"
        >
          <b-icon-check></b-icon-check>
          {{ $t('Update') }}
        </b-button>
      </b-row>
    </b-card>
  </b-col>

</template>

<script>
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BAvatar,
  BBreadcrumb,
  BBreadcrumbItem,
  BButton,
  BButtonGroup,
  BCard,
  BCardCode,
  BCol,
  BForm,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BMedia,
  BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import { ref } from '@vue/composition-api'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import 'cleave.js/dist/addons/cleave-phone.us'
import { maxLength, numeric, required } from 'vuelidate/lib/validators'
import myUpload from 'vue-image-crop-upload/upload-2.vue'
import PersonEdit from '@/components/PersonComponents/PersonEdit'

import flatPickr from 'vue-flatpickr-component'
import { Turkish } from 'flatpickr/dist/l10n/tr.js'
import 'flatpickr/dist/flatpickr.css'

export default {
  setup() {
    const userImg = ref('')
    const previewEl = ref(null)
    let refInputEl = ref(null)
    const { inputImageRenderer } = useInputImageRenderer(refInputEl, function (
        base64
    ) {
      userImg.value = base64
    });
    return {
      refInputEl,
      inputImageRenderer,
      userImg,
      previewEl,
    };
  },
  components: {
    PersonEdit,
    // TabContent,
    BButton,
    BInputGroupPrepend,
    BButtonGroup,
    BAvatar,
    BFormDatepicker,
    BInputGroupAppend,
    BImg,
    BInputGroup,
    BMedia,
    BCard,
    BCardCode,
    BRow,
    BCol,
    Cleave,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    BBreadcrumb,
    BBreadcrumbItem,
    BForm,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    flatPickr,
    'my-upload': myUpload
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      //min_date: "1940-01-01",
      //max_date: "2009-12-31",
      image: '',
      formatted: '',
      selected: '',
      gender: 3,
      personData: {
        phone_number: '',
        gender: 3,
        avatar: '',
        name: '',
      },
      date: {
        date: true,
        delimiter: '-',
        datePattern: ['Y', 'm', 'd'],
      },
      block: {
        blocks: [25],
        uppercase: false,
      },
      //   codeIcon,
      maleColor: 'outline-primary',
      femaleColor: 'outline-primary',
      person_id: '',
      config: {
        dateFormat: 'd.m.Y',
        locale: this.$i18n.locale === 'en' ? '' : Turkish,
        maxDate: '12.12.2009',
        minDate: '01.01.1940',
      },
      // inputImageRenderer:{},
      show: false,
      params: {
        token: '123456798',
        name: 'avatar'
      },
      headers: {
        smail: '*_~'
      },
      userImg: '',
      isFinal:false
    };
  },
  methods: {
    getPersonData() {

      this.$database.StudioService.get_person_by_person_id(this.person_id).then
      (
          (res) =>
          {
            //  if (Object.keys(res.result).length === 0)
            //    return;

            //  const full_name = res.result.name + " " + res.result.last_name;
            // this.personData.full_name =res.result. full_name;
            this.personData.name = res.result.name;
            this.personData.last_name = res.result.last_name;
            this.personData.email = res.result.email;
            this.personData.gender= res.result.gender;
            this.personData.phone_number=res.result.phone_number;
            this.personData.birthday = this.$functions.ConvertToTurkishDateFormat(res.result.birthday);
            this.personData.user_img=res.result.image;

            this.userImg = this.personData.user_img; //- api img update gelince aktif olacak

            this.getGender(this.personData.gender)

          }
      );
    },
    updatePersonData() {
      this.isFinal = true
      if (this.$v.$invalid)
      {
        console.log("doğrulanamadı")
        return;
      }
      this.$database.FacilityService.FacilityPersonCreate(
      //   {
      //   CompanyBranchId: this.$route.params.studioID,
      //   FirstName: this.personData.name,
      //   LastName: this.personData.last_name,
      //   EMAil: this.personData.email,
      //   PhoneNumber: this.personData.phone_number,
      //   Birthday:this.$functions.ConvertToEnglishDateFormat(this.personData.birthday),
      // // this.personData.birthday === null || this.personData.birthday === ''
      // //     ? '1899-01-01'
      // //     : this.personData.birthday.split('.')
      // //         .reverse()
      // //         .join('-'),
      //   Gender: this.gender,
      //   UserImg: this.userImg, //şu anlık
      // }
      this.$models.personCreateModel(this.studio_id, this.personData.name, this.personData.last_name, this.personData.email.toLowerCase(), this.personData.phone_number, this.$functions.ConvertToEnglishDateFormat(this.personData.birthday),this.gender,this.userImg)
      )
          .then((res) => {
            if (res.IsSuccess == true || res.IsSuccess == 1) {
              if (res.Result == 'Tehsis kullanıcısı tanımlandı') {
                this.$functions.Messages.success('Facility User Successfully Identified');
                this.$router.push({
                  name: 'studio_user_list',
                  params: { studioID: this.$route.params.studioID }
                });
              } else {
                this.$functions.Messages.success('Facility User Successfully Edited');
                this.$router.push({
                  name: 'studio_user_list',
                  params: { studioID: this.$route.params.studioID }
                });
              }
            } else {
              this.$functions.Messages.error('Action failed!');
            }
          });
      // this.$database.StudioService.person_update_by_person_id(
      //   this.personData.person_id,
      //   this.personData.name,
      //   this.personData.last_name,
      //   this.personData.phone_number,
      //   this.personData.birthday === null || this.personData.birthday === ""
      //     ? "1899-01-01"
      //     : this.personData.birthday.split(".").reverse().join("-"),
      //   this.gender,
      //   // this.userImg - api ye img update gelince aktif edeceğim
      // ).then((res) => {
      //   if (!res.is_success) {
      //     this.$toast({
      //       component: ToastificationContent,
      //       props: {
      //         title: this.$t("Action failed!"),
      //         icon: "xIcon",
      //         variant: "danger",
      //       },
      //     });
      //     return;
      //   }
      //   this.$toast({
      //     component: ToastificationContent,
      //     props: {
      //       title: this.$t("Successfully edited!"),
      //       icon: "EditIcon",
      //       variant: "success",
      //     },
      //   });
      //   this.$router.push({
      //     name: "customerList",
      //   });
      // });
    },
    getGender(val) {
      // this.gender = val;
      if (val === 'female' || val === 0) {
        this.femaleColor = 'primary';
        this.maleColor = 'outline-secondary';
        this.gender = 0;
      } else if (val === 'male' || val === 1) {
        this.gender = 1;
        this.maleColor = 'primary';
        this.femaleColor = 'outline-secondary';
      } else {
        this.gender = 3;
      }
    },
    removePhoto() {
      //temp default user img
      this.userImg = 'https://cdn4.iconfinder.com/data/icons/small-n-flat/24/user-alt-512.png';
    },
    toggleShow() {
      this.show = !this.show;
    },
    /**s
     * crop success
     *
     * [param] userImg
     * [param] field
     */
    cropSuccess(userImg, field) {
      this.userImg = userImg;
      console.log(this.userImg);
    },
    /**
     * upload success
     *
     * [param] jsonData  server api return data, already json encode
     * [param] field
     */
    cropUploadSuccess(jsonData, field) {
      console.log('-------- upload success --------');
      console.log(jsonData);
      console.log('field: ' + field);
    },
    /**
     * upload fail
     *
     * [param] status    server api return error status, like 500
     * [param] field
     */
    cropUploadFail(status, field) {
      console.log('-------- upload fail --------');
      console.log(status);
      console.log('field: ' + field);
    },
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no - date - selected` string
      this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedDMY;
    },
  },
  validations: {
    personData: {
      name: {
        required,
      },
      last_name: {
        required,
      },
      phone_number: {
        required: required,
        numeric: numeric,
        maxLength: maxLength(20)
      },
      birthday: {
        required:required
      },
      gender:{
        numeric:numeric
      }
    },
  },
  created() {
    this.studio_id =  this.$route.params.studioID;
    this.userImg = 'https://cdn4.iconfinder.com/data/icons/small-n-flat/24/user-alt-512.png'
    this.person_id = this.$route.params.personID
    this.getPersonData()
  },

};
</script>

<!-- stillendirme -->
<style lang="css" src="@/styles/css/personEdit.css" scoped></style>
<style lang="scss" src="@/styles/scss/personEdit.scss" scoped></style>
